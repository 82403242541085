'use strict';

/**
 * Generating Dynamic Styles to decrease CSS size and execute faster loading times.
 * 
 * NOTE:
 * - colors must be in HEX format.
 * - use the color scheme as below this will automatically generate CSS for:
 *    `bg-colorName-dark bg-colorName-light color-colorName-dark color-colorName-light`
 *    `["colorName","light_hex","dark_hex","darker_hex_for_gradient"]`
 */
 const COLORS = [
  ["none","","",""],
  ["red1","#D8334A","#BF263C","#9d0f23"],
  ["red2","#ED5565","#DA4453","#a71222"],
  ["orange","#FC6E51","#E9573F","#ce3319"],
  ["yellow1","#FFCE54","#F6BB42","#e6a00f"],
  ["yellow2","#E8CE4D","#E0C341","#dbb50c"],
  ["green1","#A0D468","#8CC152","#5ba30b"],
  ["green2","#2ECC71","#2ABA66","#0da24b"],
  ["mint","#48CFAD","#37BC9B","#0fa781"],
  ["teal","#A0CECB","#7DB1B1","#158383"],
  ["aqua","#4FC1E9","#3BAFDA","#0a8ab9"],
  ["blue1","#4FC1E9","#3BAFDA","#0b769d"],
  ["blue2","#5D9CEC","#4A89DC","#1a64c6"],
  ["magenta1","#AC92EC","#967ADC","#704dc9"],
  ["magenta2","#8067B7","#6A50A7","#4e3190"],
  ["pink1","#EC87C0","#D770AD","#c73c8e"],
  // ["pink2","#fa6a8e","#fb3365","#d30e3f"],
  ["pink2","#885DFF","#6A35FF","#3E01E9"],
  ["brown1","#BAA286","#AA8E69","#896b43"],
  ["brown2","#8E8271","#7B7163","#584934"],
  ["gray1","#F5F7FA","#E6E9ED","#c2c5c9"],
  ["gray2","#CCD1D9","#AAB2BD","#88919d"],
  ["dark1","#656D78","#434A54","#242b34"],
  ["dark2","#3C3B3D","#323133","#1c191f"]
];

const SOCIAL_COLORS = [
  ["facebook","#3b5998"],
  ["linkedin","#0077B5"],
  ["twitter","#4099ff"],
  ["google","#d34836"],
  ["whatsapp","#34AF23"],
  ["pinterest","#C92228"],
  ["sms","#27ae60"],
  ["mail","#3498db"],
  ["dribbble","#EA4C89"],
  ["phone","#27ae60"],
  ["skype","#12A5F4"],
  ["instagram","#e1306c"]
];

// cookie KEYs to avoid typo
const TOAST_INDEX_TITLE = 'toast_index_notification_title',
      TOAST_INDEX_CONTENT = '',
      TOAST_GRN_TITLE = 'toast_grn_notification_title',
      TOAST_GRN_CONTENT = 'toast_grn_notification_content',
      TOAST_FM_TITLE = 'toast_fm_notification_title',
      TOAST_FM_CONTENT = 'toast_fm_notification_content',
      IS_LOGGED_IN = 'areca_user_is_login',
      LOGIN_USER_ID = 'areca_user_id',
      LOGIN_TOKEN = 'areca_user_jwt',
      LAST_GRN_DRAFT = 'last_grn_draft_created',
      SEARCH_PO_REF_NO = 'search_po_ref_no',
      LAST_GRN_ADD_DRAFT ='last_grn_add_draft_created',
      SEARCH_GRN_ADD_DRAFT ='search_grn_draft_ref_no',
      YST_BIN_CODE = 'yearly_stock_take_bin_code',
      FM_ITEM_ID = 'fresh_market_item_id',
      FM_SESSION_ID = 'fresh_market_session_id';
      // SEARCH_GRN = 'toast_index_notification_content';

/**
 * Use this module to handle all cookie related
 */
const ArecaCookies = (function() {
  function createCookie(e, t, n) {if (n) {var o = new Date;o.setTime(o.getTime() + 48 * n * 60 * 3600 * 1e3);var r = "; expires=" + o.toGMTString()} else var r = "";document.cookie = e + "=" + t + r + "; path=/"}
  function readCookie(e) {for (var t = e + "=", n = document.cookie.split(";"), o = 0; o < n.length; o++) {for (var r = n[o];" " == r.charAt(0);) r = r.substring(1, r.length);if (0 == r.indexOf(t)) return r.substring(t.length, r.length)}return null}
  function eraseCookie(e) {createCookie(e, "", -1)}

  function clearLogin() {
    eraseCookie(IS_LOGGED_IN);
    eraseCookie('areca_user_id');
    eraseCookie('areca_user_jwt')
  }

  function clearGrnToast() {
    eraseCookie('toast_grn_notification_title');
    eraseCookie('toast_grn_notification_content');
  }

  function clearIndexToast() {
    eraseCookie('toast_index_notification_title');
    eraseCookie('toast_index_notification_content');
  }

  function clearSearch() {
    eraseCookie('search_po_ref_no');
    eraseCookie('search_grn_draft_ref_no');
  }

  function clearAll() {
    clearLogin();
    clearIndexToast();
    clearGrnToast();
    clearSearch();
  }
  
  /**
   * 
   * @param {Number} user_id 
   * @param {String} token 
   */
  function createLoginCookies(user_id, token) {
    createCookie(LOGIN_USER_ID, user_id, 2);
    createCookie(LOGIN_TOKEN, token, 2);
    createCookie(IS_LOGGED_IN, 'true', 2);
  }

  return {
    clearAll: clearAll,
    create: createCookie,
    read: readCookie,
    clear: eraseCookie,
    createLogin: createLoginCookies,
    clearIndexToast: clearIndexToast,
  }
})();

const ArecaNavigator = (function() {
  /**
   * @param {String} path 
   */
  function redirectTo(path) {
    window.location.href = path;
  }

  return {
    redirectTo: redirectTo
  }
})();

const AuthController = (function() {
  function isAuthenticated() {
    return ArecaCookies.read(IS_LOGGED_IN) != null;
  }

  function hideLoginButton() {
    $('.login-content-hide').hide();
  }

  function redirectToLoginPage() {
    ArecaNavigator.redirectTo(LoginPage.path);
  }

  function init() {
    authenticate();
  }

  function authenticate() {
    if (!$('.page-login').length) {
      if (isAuthenticated()) {
        hideLoginButton();
      } else {
        redirectToLoginPage();
        return false;
      }
    }
    return true;
  }

  return {
    init: init,
    authenticate: authenticate,
  }
})();

/** Barcode */
const ArecaBarcode = (function() {
  /**
   * @param {String} elementIdentifier 
   * @param {String} barcode 
   */
  function showBarCode(elementIdentifier, barcode) {
    JsBarcode(elementIdentifier, barcode, {
      width: 1,
      height: 40,
      displayValue: true
    });
  }
  return {
    show: showBarCode,
  }
})();


/** Loader */
const Loader = (function() {
  function show() {
    $('#preloader').removeClass('preloader-hide');
  }

  function hide() {
    $('#preloader').addClass('preloader-hide');
  }

  return {
    show: show,
    hide: hide,
  }
})();


const StyleGenerator = (function() {
  let generatedStyles = null;
  let generatedHighlight = null;

  function HEXtoRGBA(hex){
    let c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){c= [c[0], c[0], c[1], c[1], c[2], c[2]];}
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.3)';
    }
  }

  function highlightColors(){
    const colorScheme = ArecaCookies.read('azures-color-scheme');
    const bodyColor = colorScheme != null ? colorScheme :  $('body').data('highlight');
  
    const data = COLORS.map(colorsArray => colorsArray[0]);
    if (data.indexOf(bodyColor) > -1) {
        var highlightLocated = data.indexOf(bodyColor)
        var highlightColorCode = COLORS[highlightLocated][2]
        var highlightColor = '.color-highlight{color:'+highlightColorCode+'!important}'
        var highlightBg = '.bg-highlight, .page-item.active a{background-color:'+highlightColorCode+'!important}'
        var highlightNav = '.footer-bar-1 .active-nav *, .footer-bar-3 .active-nav i{color:'+highlightColorCode+'!important} .footer-bar-2 strong, .footer-bar-4 strong, .footer-bar-5 strong{background-color:'+highlightColorCode+'!important; color:#FFF;}'
        var highlightBorder = '.border-highlight{border-color:'+highlightColorCode+'!important}'
        var highlightHeaderTabs = '.header-tab-active{border-color:'+highlightColorCode+'!important}'
        if(!generatedHighlight.length){
            $('body').append('<style class="generated-highlight"></style>')
            $('body').append('<style class="generated-background"></style>')
            $('.generated-highlight').append(highlightColor, highlightBg, highlightNav, highlightBorder, highlightHeaderTabs);
        }
    }
  }

  function colorChanger(){
    $('[data-change-highlight]').on('click',function(changeColor){
        var highlightNew = $(this).data('change-highlight');
        $('body').attr('data-highlight',highlightNew);
        $('.generated-highlight').remove();
        ArecaCookies.create('azures-color-scheme',highlightNew,1)
        var data = COLORS.map(colorsArray => colorsArray[0]);
            if (data.indexOf(highlightNew) > -1) {
                var highlightLocated = data.indexOf(highlightNew)
            if($(this).data('color-light') !== undefined){
                var highlightColorCode = colorsArray[highlightLocated][1]
            } else {
                var highlightColorCode = colorsArray[highlightLocated][2]
            }
            var highlightColor = '.color-highlight{color:'+highlightColorCode+'!important}'
            var highlightBg = '.bg-highlight{background-color:'+highlightColorCode+'!important}'
            var highlightNav = '.footer-bar-1 .active-nav *, .footer-bar-3 .active-nav i{color:'+highlightColorCode+'!important} .footer-bar-2 strong, .footer-bar-4 strong, .footer-bar-5 strong{background-color:'+highlightColorCode+'!important; color:#FFF;}'
            var highlightBorder = '.border-highlight{border-color:'+highlightColorCode+'!important}'
            $('body').append('<style class="generated-highlight"></style>')
            $('.generated-highlight').append(highlightColor, highlightBg, highlightNav, highlightBorder);
        }
        return false;
    });
  }

  function colorized() {
    if (!generatedStyles.length){
      $('body').append('<style class="generated-styles"></style>');
      $('.generated-styles').append('/*Generated using JS for lower CSS file Size, Easier Editing & Faster Loading*/');
      COLORS.forEach(function (colorValue) {$('.generated-styles').append('[data-feather-bg="'+colorValue[0]+'-dark"]{ fill: '+colorValue[2]+'!important;} [data-feather-bg="'+colorValue[0]+'-light"]{ fill: '+colorValue[1]+'!important;}')});
      COLORS.forEach(function (colorValue) {$('.generated-styles').append('[data-feather-bg="'+colorValue[0]+'-fade-dark"]{ fill: '+HEXtoRGBA(colorValue[2])+'!important;} [data-feather-bg="'+colorValue[0]+'-fade-light"]{ fill: '+HEXtoRGBA(colorValue[1])+'!important;}')});
      COLORS.forEach(function (colorValue) {$('.generated-styles').append('[data-feather-color="'+colorValue[0]+'-dark"]{ stroke: '+colorValue[2]+'!important;} [data-feather-color="'+colorValue[0]+'-light"]{ stroke: '+colorValue[1]+'!important;}')});
      COLORS.forEach(function (colorValue) {$('.generated-styles').append('[data-feather-color="'+colorValue[0]+'-fade-dark"]{ stroke: '+HEXtoRGBA(colorValue[2])+'!important;} [data-feather-color="'+colorValue[0]+'-fade-light"]{ stroke: '+HEXtoRGBA(colorValue[1])+'!important;}')});
      COLORS.forEach(function (colorValue) {$('.generated-styles').append('.bg-'+colorValue[0]+'-light{ background-color: '+colorValue[1]+'!important; color:#FFFFFF!important;} .bg-'+colorValue[0]+'-light i, .bg-'+colorValue[0]+'-dark i{color:#FFFFFF;} .bg-'+colorValue[0]+'-dark{  background-color: '+colorValue[2]+'!important; color:#FFFFFF!important;} .border-'+colorValue[0]+'-light{ border-color:'+colorValue[1]+'!important;} .border-'+colorValue[0]+'-dark{  border-color:'+colorValue[2]+'!important;} .color-'+colorValue[0]+'-light{ color: '+colorValue[1]+'!important;} .color-'+colorValue[0]+'-dark{  color: '+colorValue[2]+'!important;}');});
      COLORS.forEach(function (colorFadeValue) {$('.generated-styles').append('.bg-fade-'+colorFadeValue[0]+'-light{ background-color: '+ HEXtoRGBA(colorFadeValue[1]) + '!important; color:#FFFFFF;} .bg-fade-'+colorFadeValue[0]+'-light i, .bg-'+colorFadeValue[0]+'-dark i{color:#FFFFFF;} .bg-fade-'+colorFadeValue[0]+'-dark{  background-color: '+HEXtoRGBA(colorFadeValue[2])+'!important; color:#FFFFFF;} .border-fade-'+colorFadeValue[0]+'-light{ border-color:'+HEXtoRGBA(colorFadeValue[1])+'!important;} .border-fade-'+colorFadeValue[0]+'-dark{  border-color:'+HEXtoRGBA(colorFadeValue[2])+'!important;} .color-fade-'+colorFadeValue[0]+'-light{ color: '+HEXtoRGBA(colorFadeValue[1])+'!important;} .color-fade-'+colorFadeValue[0]+'-dark{  color: '+HEXtoRGBA(colorFadeValue[2])+'!important;}');});
      COLORS.forEach(function (gradientValue) {$('.generated-styles').append('.bg-gradient-'+gradientValue[0]+'{background-image: linear-gradient(to bottom, '+gradientValue[1]+' 0, '+gradientValue[2]+' 100%)}')});
      SOCIAL_COLORS.forEach(function (socialColorValue) {$('.generated-styles').append('.bg-'+socialColorValue[0]+'{background-color:'+socialColorValue[1]+'!important; color:#FFFFFF;} .color-'+socialColorValue[0]+'{color:'+socialColorValue[1]+'!important;}')});
      COLORS.forEach(function (gradientBodyValue) {$('.generated-styles').append('.body-'+gradientBodyValue[0]+'{background-image: linear-gradient(to bottom, '+gradientBodyValue[1]+' 0, '+gradientBodyValue[3]+' 100%)}')});
    }
  }

  function initElements() {
    generatedStyles = $('.generated-styles');
    generatedHighlight = $('.generated-highlight');
  }

  function init() {
    initElements();
    highlightColors();
    colorChanger();
    colorized();
  }

  return {
    init: init
  }
})();

/** UI */
const UI = (function() {
  let menu = null,
      body = null,
      menuFixed =  null,
      menuFooter =  null,
      menuClose = null,
      header = null,
      pageAll = null,
      pageContent =  null,
      headerAndContent = null,
      menuHider = null,
      menuDeployer = null;
  
  function showMenu() {
    $('.menu').css('display','block');
  }

  function onLoad() {
    $(window).on('load',function(){
      showMenu();
      Loader.hide();
    });
  }

  function initUIComponents() {
    menu = $('.menu');
    body = $('body');
    menuFixed = $('.nav-fixed');
    menuFooter = $('#footer-bar');
    menuClose = $('.close-menu');
    header = $('.header');
    pageAll = $('#page');
    pageContent = $('.page-content');
    headerAndContent = $('.header, .page-content, #footer-bar');
    menuHider = $('.menu-hider');
    menuDeployer = $('[data-menu]');
  }

  function scrollToTop() {
    history.scrollRestoration = 'manual';
  }

  function isScrollToTop() {
    return 'scrollRestoration' in history;
  }

  function disablePageJump() {
    $('a').on('click', function(){var attrs = $(this).attr('href'); if(attrs === '#'){return false;}});
  }

  function createMenuHider() {
    if(!$('.menu-hider').length) $('#page').append('<div class="menu-hider"><div>');
  }

  function setMenuSize() {
    menu.each(function(){
      const menuHeight = $(this).data('menu-height');
      const menuWidth = $(this).data('menu-width');
      // const menuActive = $(this).data('menu-active');

      if($(this).hasClass('menu-box-right')){$(this).css("width",menuWidth);}
      if($(this).hasClass('menu-box-left')){$(this).css("width",menuWidth);}
      if($(this).hasClass('menu-box-bottom')){$(this).css("height",menuHeight);}
      if($(this).hasClass('menu-box-top')){$(this).css("height",menuHeight);}
      if($(this).hasClass('menu-box-modal')){$(this).css({"height":menuHeight, "width":menuWidth});}
    });
  }

  function openModal() {
    $('body').addClass('modal-open');
  }

  function closeModal() {
    $('body').removeClass('modal-open');
  }

  function onCloseMenu() {
    menu.removeClass('menu-active');
    menuHider.removeClass('menu-active menu-active-clear');
    headerAndContent.css('transform','translate(0,0)');
    menuHider.css('transform','translate(0,0)');

    $('#footer-bar').removeClass('footer-menu-hidden');
    closeModal();

    return false;
  }

  function detectActiveMenu() {
    const id = $('#menu-main').data('menu-active');
    $(`#${id}`).addClass('nav-item-active');
    $('[data-submenu]').on('click',function(){
        $(this).toggleClass('nav-item-active');
        $(this).find('.fa-chevron-right').toggleClass('rotate-90');
        const subMenuId = $(this).data('submenu');
        $('#'+subMenuId).slideToggle(250);
        return false;
    });
    $('[data-submenu]').each(function(){
        const subMenuId = $(this).data('submenu');
        const subChildren = $(`#${subMenuId}`).children().length;
        $(this).find('strong').html(subChildren);
    });
    if($('.nav-item-active').data('submenu')){
        $('.nav-item-active').find('.fa-chevron-right').toggleClass('rotate-90');
        const subMenuId = $('.nav-item-active').data('submenu');
        $(`#${subMenuId}`).slideDown(250);
    }
    if($('.nav-item-active').parent().hasClass('submenu')){
        $('.nav-item-active').parent().slideDown(250);
    }
  }

  function attachOnCloseMenu() {
    $('.menu-hider, .close-menu, .menu-close').on('click', onCloseMenu);
  }

  function initMenu() {
    setMenuSize();
    attachOnClickMenuDeployer();
    closeModal();
    attachOnCloseMenu();

    setTimeout(detectActiveMenu, 250)
  }

  function deactivateMenu() {
    menu.removeClass('menu-active');
  }

  function activateMenuHider() {
    menuHider.addClass('menu-active');
  }

  /** @param {Element} menuItem */
  function getMenuItemData(menuItem) {
    const effect = menuItem.data('menu-effect');
    const width = menuItem.data('menu-width')
    const height = menuItem.data('menu-height');
    const classList = menuItem.attr("class");

    return {
      effect: effect,
      width: width,
      height: height,
      classList: classList,
    }
  }

  /** @param {Element} menuItem */
  function syncMenuHider(menuItem) {
    if (menuItem.hasClass('menu-header-clear')) menuHider.addClass('menu-active-clear');
  }

  /** @param {Element} menuItem */
  function toggleMenuItem(menuItem) {
    menuItem.toggleClass('menu-active');
  }

  /** @param {Element} menuItem */
  function hideFooterMenu(menuItem) {
    if (menuItem.hasClass('menu-box-bottom')) $('#footer-bar').addClass('footer-menu-hidden');
  }

  /** 
   * @param {Element} menuItem 
   * @param {JSONObject} attr
   * 
  */
   function runParallaxEffect(menuItem, attr) {
    const width = attr.width;

    if (menuItem.hasClass('menu-box-bottom')) { 
      headerAndContent.css("transform", "translateY("+(width/5)*(-1)+"px)");
    }
    if (menuItem.hasClass('menu-box-top')) { 
      headerAndContent.css("transform", "translateY("+(width/5)+"px)");
    }
    if (menuItem.hasClass('menu-box-left')) { 
      headerAndContent.css("transform", "translateX("+(width/5)+"px)");
    }
    if (menuItem.hasClass('menu-box-right')) {
      headerAndContent.css("transform", "translateX("+(width/5)*(-1)+"px)");
    }
  }

  /** 
   * @param {Element} menuItem 
   * @param {JSONObject} attr
   * 
  */
   function runPushEffect(menuItem, attr) {
    const height = attr.height;
    const width = attr.width;

    if (menuItem.hasClass('menu-box-bottom')){
      headerAndContent.css("transform", "translateY("+(height)*(-1)+"px)");
    }
    if (menuItem.hasClass('menu-box-top')){
      headerAndContent.css("transform", "translateY("+(height)+"px)");
    }
    if (menuItem.hasClass('menu-box-left')){
      headerAndContent.css("transform", "translateX("+(width)+"px)");
    }
    if (menuItem.hasClass('menu-box-right')) {
      headerAndContent.css("transform", "translateX("+(width)*(-1)+"px)");
    }
  }

  /** 
   * @param {Element} menuItem 
   * @param {JSONObject} _
   * 
  */
   function runPushFullEffect(menuItem, _) {
    if (menuItem.hasClass('menu-box-left')) {
      headerAndContent.css("transform", "translateX(100%)");
    }
    if (menuItem.hasClass('menu-box-right')) {
      headerAndContent.css("transform", "translateX(-100%)");
    }
  }

  const effects = {
    'menu-parallax': runParallaxEffect,
    'menu-push': runPushEffect,
    'menu-push-full': runPushFullEffect,
  };

  /** @param {Element} menuItem */
  function runMenuItemEffect(menuItem) {
    const itemAttributes = getMenuItemData(menuItem)

    effects[itemAttributes.effect]?.call(menuItem, itemAttributes);
  }

  function runGrnImportEffect(){
    $("#menu-grn-import-item-qty").attr('data-index',$(this).data('index'));
    $(".menu-grn-import-item-name").text($(this).find('.grn-import-item-name').text());
    $("#grn-import-scan-qty").val($(this).find('.received-qty').text());
    $("#grn-import-scan-qty").focus();
    $("#grn-import-scan-qty").select();
    $('html, body').animate({ scrollTop: ($("#grn-import-scan-qty").offset().top - 80) }, 1);
  }

  function runGrnAddItemEffect(){
    $("#menu-grn-add-item-qty").attr('data-index',$(this).data('index'));
    $(".menu-grn-add-item-name").text($(this).find('.grn-add-item-name').text());
    $("#grn-add-scan-qty").val($(this).find('.received-qty').text());
    $("#grn-add-scan-qty").focus();
    $("#grn-add-scan-qty").select();
    $('html, body').animate({ scrollTop: ($("#grn-add-scan-qty").offset().top - 80) }, 1);
  }

  function runYstAddItemEffect(){
    $("#yst-add-item-qty").focus();
    $("#yst-add-item-qty").select();
    $('html, body').animate({ scrollTop: ($("#yst-add-item-qty").offset().top - 80) }, 1);
  }

  const customEffects = {
    'menu-grn-import-item-qty': runGrnImportEffect,
    'menu-grn-add-item-qty': runGrnAddItemEffect,
    'menu-yst-add-item-qty': runYstAddItemEffect,
  };

  /** @param {String} menuId */
  function runCustomEffect(menuId) {
    customEffects[menuId]?.call()
  }

  /** @param {Event} event */
  function onClickMenuDeployer(event) {
    deactivateMenu();
    activateMenuHider();
    const menuId = `#${$(this).data('menu')}`;
    const menuItem = $(menuId);
    // const menuItemData = getMenuItemData();

    openModal();
    syncMenuHider(menuItem);
    hideFooterMenu(menuItem);
    runMenuItemEffect(menuItem);
    toggleMenuItem(menuItem);
    runCustomEffect(menuId);

    return false;
  }

  function initFooterMenuItems() {
    if($('.footer-bar-2, .footer-bar-4, .footer-bar-5').length){
      if(!$('.footer-bar-2 strong, .footer-bar-4 strong, .footer-bar-5 strong').length){
          $('.footer-bar-2 .active-nav, .footer-bar-4 .active-nav, .footer-bar-5 .active-nav').append('<strong></strong>')
      }
  }
  }

  function attachOnClickMenuDeployer() {
    menuDeployer.on('click', onClickMenuDeployer);
  }

  function initAutoActivatedMenu() {
    if ($('[data-auto-activate]').length){
      autoActivateMenu.addClass('menu-active');
      menuHider.addClass('menu-active');
    }
  }

  function initBackButton() {
    const backButton = $('.back-button, [data-back-button]');
    backButton.on('click', function() {
        window.history.go(-1);
        //return false;
    });
  }

  function initCopyrightYear() {
    const copyrightYear = $('.copyright-year, #copyright-year');
    const dteNow = new Date(); 
    const intYear = dteNow.getFullYear();
    copyrightYear.html(intYear)
  }

  function backToTop(){
    $('.back-to-top').off().on( "click", function(e){e.preventDefault(); $('html, body').animate({scrollTop: 0}, 350); return false; });
  }

  function initBacktoTopBadges() {
    const backToTop = $('.back-to-top, [data-back-to-top], .back-to-top-badge, .back-to-top-icon')

    backToTop.on( "click", function(e){
        e.preventDefault();
        $('html, body, .page-content').animate({
            scrollTop: 0
        }, 350);
        return false;
    });
  }

  function initSharingLinks() {
    var share_link = window.location.href;
    var share_title = document.title;
    $('.shareToFacebook').prop("href", "https://www.facebook.com/sharer/sharer.php?u="+share_link)
    $('.shareToLinkedIn').prop("href", "https://www.linkedin.com/shareArticle?mini=true&url="+share_link+"&title="+share_title+"&summary=&source=")
    $('.shareToTwitter').prop("href", "https://twitter.com/home?status="+share_link)
    $('.shareToPinterest').prop("href", "https://pinterest.com/pin/create/button/?url=" + share_link)
    $('.shareToWhatsApp').prop("href", "whatsapp://send?text=" + share_link)
    $('.shareToMail').prop("href", "mailto:?body=" + share_link);
    //Copy Link
    $('.shareToCopyLink').on('click',function(element){
        $(this).parent().find('p').html('Link Copied');
        $(this).removeClass('bg-blue2-dark').addClass('bg-green1-dark');
        $(this).find('i').attr('class','');
        $(this).find('i').attr('class','fa fa-check font-22');
        setTimeout(function(){
            $('.shareToCopyLink').parent().find('p').html('Copy Link');
            $('.shareToCopyLink').removeClass('bg-green1-dark').addClass('bg-blue2-dark');
            $('.shareToCopyLink').find('i').attr('class','');
            $('.shareToCopyLink').find('i').attr('class','fa fa-link font-22');
        },1000);
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(share_link).select();
        document.execCommand("copy");
        $temp.remove();
    });
  }

  function initImagePreload() {
    const preloadImages = $('.preload-img');
    $(function() {preloadImages.lazyload({threshold : 500});});
  }

  function initFeatherIcons(){
    //Activating Feather Icons
    feather.replace();
    var featherIcon = $('.feather');
    if(featherIcon.length){
        featherIcon.each(function(){
           $(this).attr('stroke-width', $(this).data('feather-line'));
           $(this).attr('width', $(this).data('feather-size'));
           $(this).attr('height', $(this).data('feather-size'));
           $(this).css('width', $(this).data('feather-size'));
           $(this).css('height', $(this).data('feather-size'));
        });
    }
  }

  function showLocalError() {
    if (window.location.protocol === "file:"){$('a').on('mouseover',function(){console.log("You are seeing these errors because your file is on your local computer. For real life simulations please use a Live Server or a Local Server such as AMPPS or WAMPP or simulate a  Live Preview using a Code Editor like http://brackets.io (it's 100% free) - PWA functions and AJAX Page Transitions will only work in these scenarios.");});}
  }

  function reloadMenu() {
    createMenuHider();
    initUIComponents();
    if (isScrollToTop) scrollToTop();
    disablePageJump();
    initMenu();
    initAutoActivatedMenu();
    initFooterMenuItems();
    initBackButton();
    backToTop();
  }

  function loadExternalMenu()  {
    var menuLoad = $('[data-menu-load]');
      menuLoad.each(function(){
          var loader = $(this).data('menu-load');
          $(this).load(loader, function(){
              if (ArecaCookies.read(IS_LOGGED_IN) != null){
                  $('.login-content-hide').hide();
              } else {
                  $('.public-content-hide').hide();
              }
          });
      }).promise().done(function(){
        setTimeout(reloadMenu, 500);
    });
  }

  function extendCover() {
    const headerHeight = $('.header').height();
    const footerHeight = $('#footer-bar').height();
  
    if(header.length && menuFooter.length){
      $(this).css('height', windowHeight  - headerHeight - footerHeight)
      $('.map-full, .map-full iframe').css('height', windowHeight - headerHeight - footerHeight +14)
    } else {
      $(this).css('height', windowHeight)
      $('.map-full, .map-full iframe').css('height', windowHeight)
    }
  }
  
  function extendCoverCard() {
    $(this).css('height', windowHeight  - 180)
    $('.map-full, .map-full iframe').css('height', windowHeight - 180)
  }
  
  const extendCards = {
    'cover': extendCover,
    'cover-card': extendCoverCard,
  }
  
  function getWindowHeight() {
    let windowHeight = 0;
    if(!$('.is-on-homescreen').length){
        windowHeight = window.innerHeight
    }
  
    return windowHeight;
  }
  
  function extendCard() {
    const windowHeight = getWindowHeight();
    $('.page-content, #page').css('min-height', windowHeight);
  
    $('[data-card-height]').each(function(){
      const cardHeight = $(this).data('card-height');
      const headerHeight = $('.header').height();
      const footerHeight = $('#footer-bar').height();
      $(this).css('height', cardHeight);
      extendCards[cardHeight]?.call()
    });
  }

  function attachOnWindowResize() {
    $(window).resize(function(){
      extendCard();
    });
  }

  function initToast() {
    // console.info('initToast...');
    $('[data-toast]').on('click',function(){
      $('.toast, .snackbar-toast, .notification').toast('hide');
      $('#'+$(this).data('toast')).toast('show');
      return false;
    });
    $('[data-dismiss]').on('click',function(){
      const thisData = $(this).data('dismiss');
      $('#'+thisData).toast('hide');
    });
  }

  function initToggle() {
    //Tooltips
    // $(function () {
    //   $('[data-toggle="tooltip"]').tooltip()
    // })

    //Cancel Collapse Jump on Click
    if($('[data-toggle="collapse"]').length){
      $('[data-toggle="collapse"]').on('click',function(e){
         e.preventDefault();
      });
    }
  }

  function logout(event) {
    event.preventDefault();
    console.log('Logging out..');

    ArecaCookies.clearAll();
    ArecaNavigator.redirectTo(LoginPage.path);
  }

  // NOTE: this need to be init after 1s since it require external html to be loaded
  function initOnLogout() {
    $('#member-logout').on('click', logout);
  }

  function init() {
    reloadMenu();
    initCopyrightYear();
    initBacktoTopBadges();
    initSharingLinks();
    initImagePreload();
    initFeatherIcons();
    showLocalError();
    loadExternalMenu();
    StyleGenerator.init();
    extendCard();
    attachOnWindowResize();
    initToggle();
    initToast();
    initOnLogout();
  }
  return {
    init: init,
    extendCard: extendCard,
    onLoad: onLoad,
    initMenu: initMenu,
    initOnLogout, initOnLogout
  }
})();

const AJAXMode = (function() {
   function init(){
      const options = {
          prefetch: true,
          prefetchOn: 'mouseover',
          cacheLength: 100,
          scroll: true,
          blacklist: '.default-link',
          forms: 'contactForm',
          onStart: {
              duration:150, // Duration of our animation
              render: function ($container) {
              $container.addClass('is-exiting');// Add your CSS animation reversing class
                  $('.menu, .menu-hider').removeClass('menu-active');
                  $('#preloader').removeClass('preloader-hide');
                  return false;
              }
          },
          onReady: {
              duration: 10,
              render: function ($container, $newContent) {
                  $container.removeClass('is-exiting');// Remove your CSS animation reversing class
                  $container.html($newContent);// Inject the new content
                  setTimeout(Main.run, 0)//Timeout required to properly initiate all JS Functions.
                  $('#preloader').removeClass('preloader-hide');
              }
          },
          onAfter: function($container, $newContent) {
              setTimeout(function(){
                  $('.menu').css('display','block');
                  $('#preloader').addClass('preloader-hide');
              },150);
          }
      };
    var smoothState = $('#page').smoothState(options).data('smoothState');
      smoothState.clear();
  };

  return {
    init: init,
  }
})();

const DevMode = (function() {
  function init() {
    if(!$('.reloader').length){$('body').append('<a href="#" class="reloader" style="position:fixed; background-color:#000; color:#FFF; z-index:9999; bottom:80px; left:50%; margin-left:-105px; border-radius:10px; width:210px; line-height:40px; text-align:center;">Developer Mode - Tap to Reload</a>');}
    $('.reloader').on('click',function(){window.location.reload(true);})
    caches.delete('workbox-runtime').then(function(){});
    localStorage.clear();
    sessionStorage.clear()
    caches.keys().then(cacheNames => {
      cacheNames.forEach(cacheName => {
        caches.delete(cacheName);
      });
    });
  }

  return {
    init: init,
  }
})();

const PWAMode = (function() {
  const isMobile = {
      Android: function() {return navigator.userAgent.match(/Android/i);},
      iOS: function() {return navigator.userAgent.match(/iPhone|iPad|iPod/i);},
      Windows: function() {return navigator.userAgent.match(/IEMobile/i);},
      any: function() {return (isMobile.Android() || isMobile.iOS() || isMobile.Windows());}
  };
  function initInstallBanner() {
    $('.simulate-android-badge').on('click',function(){$('.add-to-home').removeClass('add-to-home-ios').addClass('add-to-home-visible add-to-home-android');});
    $('.simulate-iphone-badge').on('click',function(){$('.add-to-home').removeClass('add-to-home-android').addClass('add-to-home-visible add-to-home-ios');});
    $('.add-to-home').on('click',function(){$('.add-to-home').removeClass('add-to-home-visible');})
    $('.simulate-android-banner').on('click',function(){$('#menu-install-pwa-android, .menu-hider').addClass('menu-active')})
    $('.simulate-ios-banner').on('click',function(){$('#menu-install-pwa-ios, .menu-hider').addClass('menu-active')})
  }

  function initMobileDetection() {
    if (!isMobile.any()) {
      $('body').addClass('is-not-ios');
      $('.show-ios, .show-android').addClass('disabled');
      $('.show-no-device').removeClass('disabled');
    }
    if (isMobile.Android()) {
      $('body').addClass('is-not-ios');
      $('head').append('<meta name="theme-color" content="#FFFFFF"> />');
      $('.show-android').removeClass('disabled');
      $('.show-ios, .show-no-device, .simulate-android, .simulate-iphones').addClass('disabled');
    }
    if (isMobile.iOS()) {
      $('body').addClass('is-ios');
      $('.show-ios').removeClass('disabled');
      $('.show-android, .show-no-device, .simulate-android, .simulate-iphones').addClass('disabled');
    }
  }

  function init() {
    const isLoaded = $('script[src="scripts/pwa.js"]').length;
    if (!isLoaded) {
      initMobileDetection();
      initInstallBanner();
      
  
      const loadJS = function(url, implementationCode, location){
        const scriptTag = document.createElement('script');
        scriptTag.src = url;
        scriptTag.onload = implementationCode;
        scriptTag.onreadystatechange = implementationCode;
        location.appendChild(scriptTag);
      };
      function loadPWA(){}
      loadJS('scripts/pwa.js', loadPWA, document.body);
    }
  }

  return {
    init: init,
  }
})();


/** IndexPage */
const IndexPage = (function() {
  const path =  '/index.html';

  function showToast() {
    if ($('.page-index').length) {
      const title = ArecaCookies.read('toast_index_notification_title');
      const content = ArecaCookies.read('toast_index_notification_content');

      if (title?.length && content?.length) {
        $('#index-notification .title').text(ArecaCookies.read('toast_index_notification_title'));
        $('#index-notification .content').text(ArecaCookies.read('toast_index_notification_content'));
        $('#index-notification').toast('show');
        ArecaCookies.clearIndexToast();
      }
    }
  }
  
  return {
    path: path,
    init: () => {
      showToast();
    }
  }
})();

/** LoginPage */
const LoginPage = (function() {
  const path = '/login.html';

  /**
   * Show toast immediately
   * 
   * @param {String} title 
   * @param {String} message 
   */
  function showToast(title, message) {
    $('#login-notification .title').text(title);
    $('#login-notification .content').text(message);
    $('#login-notification').toast('show');
  }

  /**
   * Create toast for next page
   */
  function createToast() {
    ArecaCookies.create('toast_index_notification_title', 'Success', 1);
    ArecaCookies.create('toast_index_notification_content', 'Login successful.', 1);
  }

  function onLoginSuccess(result) {
    if (result.status.code == 'AUT-10001-S') {
      ArecaCookies.createLogin(result.session.user_id, result.session.jwt);
      createToast();
      ArecaNavigator.redirectTo(IndexPage.path);
    } else if (result.status.code == 'AUT-10001-E') {
      showToast('Error', result.status.message);
    } else {
      showToast('Error', 'Failed to login. Please try again');
    }

    Loader.hide();

    return false;
  }

  function onLoginError(result) {
    if (result.status) {
      showToast('Error', result.statusText);
    } else {
      showToast('Error', 'Failed to login. Please try again.');
    }

    ArecaCookies.clearAll();
    Loader.hide();

    return false;
  }

  /**
   * @param {Event} event 
   */
  function onLogin(event) {
    event.preventDefault();
    Loader.show();

    ArecaAPI.login({
      username: $("#user-login-username").val(),
      password: $("#user-login-password").val(),
      onSuccess: onLoginSuccess,
      onError: onLoginError
    });
  }

  function attachOnLogin() {
    $('#user-login').on('click', onLogin);
  }

  function init() {
    attachOnLogin();
  }

  return {
    path: path,
    init: init
  }
})();

