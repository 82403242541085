/* YearlyStockTake Modules */

/** YSTBinPage */
const YSTBinPage = (function() {
  const path = '/yst-bin-code.html';

  let binCodeInput = null;

  /**
  * Show toast immediately
  * 
  * @param {String} title 
  * @param {String} message 
  */
   function showToast(title, message) {
    $('#yst-bin-code-notification .title').text(title);
    $('#yst-bin-code-notification .content').text(message);
    $('#yst-bin-code-notification').toast('show');
  }

  function showBinCodeSection() {
    $('.yst-bin-code-section').show();
  }

  function onGetSessionSuccess(result) {
    if (result.status.code == 'YST-100002-S') {
      $('.outlet-name').text(result.stock_take_sessions.outlet_code);
      showBinCodeSection();
      focusAndScrollInput();
    } else {
      showToast('Error', 'Failed to get active stock take session. Please try again.');
    }

    return false;
  }

  function onGetSessionError(result) {
    if (result.responseJSON && result.responseJSON.status.code == 'AUT-10010-E'){
      const msg = result.responseJSON.status.message;
      showToast('Error', msg);
      ArecaCookies.clearAll();
      ArecaNavigator.redirectTo(LoginPage.path);
    } else if (result.responseJSON && result.responseJSON.status.code == 'YST-100002-E'){
      const msg = result.responseJSON.status.message;
      showToast('Error', msg)
    } else if (result.status) {
      const msg = result.statusText;
      showToast('Error', msg)
    } else {
      showToast('Error', 'Failed to get stock take session. Please try again.');
    }
  }

  function getCurrentActiveSession() {
    ArecaAPI.getActiveYSTSession({
      onSuccess: onGetSessionSuccess,
      onError: onGetSessionError
    });
  }

  function focusAndScrollInput() {
    binCodeInput.select();
    binCodeInput.focus();
  }

  function attachInputFocusEvent() {
    binCodeInput.focus(function () {
      $('html, body').animate({ scrollTop: ($(this).offset().top - 80) }, 1);
      return false;
    });
  }

  /**
   * @param {Event} event
   */
  function onProceed(event) {
    event.preventDefault();

    const binCode = binCodeInput.val();

    if (binCode) {
      ArecaCookies.create(YST_BIN_CODE, binCode, 1);
      ArecaNavigator.redirectTo(YSTScanPage.path);
    } else {
      showToast('Error', 'Bin code cannot be empty');
    }
  }

  function attachOnProceed() {
    $('#yst-bin-code-proceed-btn').on('click', onProceed);
  }

  /**
   * @param {KeyboardEvent} event
   */
  function inputKeyboardEvents(event) {
    if (event.key == 'Enter') {  $('#yst-bin-code-proceed-btn').click(); }
  }

  function attachInputKeyboardEvents() {
    binCodeInput.on('keypress', inputKeyboardEvents);
  }

  function initElements() {
    binCodeInput = $('#yst-bin-code-input');
  }

  function init() {
    if ($('.page-yst-bin-code').length) {
      initElements();
      attachInputFocusEvent();
      focusAndScrollInput();
      attachOnProceed();
      attachInputKeyboardEvents();
      Loader.show()
      getCurrentActiveSession();
      Loader.hide()
    }
  }

  return {
    path: path,
    init: init
  }
})();

/** YSTScanPage */
const YSTScanPage = (function() {
  const path = '/yst-add-item.html';

  let barcodeInput = null;
  let saveButton = null;
  let itemList = null;

  /**
  * Show toast immediately
  * 
  * @param {String} title 
  * @param {String} message 
  */
   function showToast(title, message) {
    $('#yst-add-item-notification .title').text(title);
    $('#yst-add-item-notification .content').text(message);
    $('#yst-add-item-notification').toast('show');
  }

  function focusAndScrollToInput() {
    barcodeInput.focus(function () {
      $('html, body').animate({ scrollTop: ($(this).offset().top - 80) }, 1);
      return false;
    });

    barcodeInput.select();
    barcodeInput.focus();
  }

  function showBinCode() {
    const binCode = ArecaCookies.read(YST_BIN_CODE);

    if (binCode) {
      $('.yst-add-item-bin-code-input').text(binCode);
      $('.yst-add-item-bin-code').text(binCode);
    } else {
      ArecaNavigator.redirectTo(YSTBinPage.path);
    }
  }

  function clearItemList() {
    itemList.empty();
  }

  function clearInput() {
    barcodeInput.val('');
    $('#yst-add-item-qty').val('');
  }

  function populateErrorItem() {
    const ystItem = '<a href="#" data-index="1" data-menu="menu-grn-import-item-qty" ' +
      'data-po-item-id="1" data-item-id="2" class="yst-import-item border border-red2-dark rounded-s shadow-s">' +
          '<i class="fa font-14 fa-angle-double-right color-red2-dark"></i>' +
          '<span>ERROR</span>' +
          '<strong class="grn-import-item-name">Please enter barcode</strong>' +
          '<u class="">QTY<span></span></u>' +
          '<i class="received-qty"></i>' +
      '</a>'
    itemList.append(ystItem);
  }

  /**
   * 
   * @param {*} barcode 
   * @param {*} quantity 
   * @return {String} errorMessage?
   */
  function getErrorMessage(barcode, quantity) {
    if (barcode.length < 1) {
      return 'Please enter a valid barcode';
    }
    if (quantity == 0) {
      return 'Quantity cannot be Zero.';
    }
    if (quantity <= -10000 || quantity >= 10000) {
      return 'Quantity must between -9999 and 9999';
    }
  }

  function populateItem(item) {
    const ystItem = '<a href="#" data-index="1" data-menu="menu-grn-import-item-qty" ' +
                'data-po-item-id="1" data-item-id="2" class="yst-import-item border border-green2-dark rounded-s shadow-s">' +
                    '<i class="fa font-14 fa-angle-double-right color-green2-dark"></i>' +
                    `<span>[${item.barcode}] - Article: ${item.article_no}</span>` +
                    `<strong class="grn-import-item-name">${item.description.slice(0, 50).trim()}</strong>` +
                    '<u class="">QTY<span></span></u>' +
                    `<i class="received-qty">${item.quantity}</i>` +
                '</a>'
    itemList.append(ystItem);
  }

  function onLogItemSuccess(result) {
    if (result.status.code == 'ITE-10001-S') {
      showToast('success', result.status.message);
      clearItemList();
      populateItem(result.yearly_stock_take_logs.item);
    }
    clearInput();
    focusAndScrollToInput();

    return false;
  }

  function onLogItemError(result) {
    if (result.responseJSON && result.responseJSON.status.code == 'AUT-10010-E') {
      showToast('Error', result.responseJSON.status.message);
      ArecaCookies.clearAll();
      ArecaNavigator.redirectTo(LoginPage.path);
    } else if (result.responseJSON){
      showToast('Error', result.responseJSON.status.message);
    } else if (result.status) {
      showToast('Error', result.statusText);
    } else {
      showToast('Error', 'Failed. Please try again.');
    }

    clearItemList();
    populateErrorItem();
    clearInput();

    return false;
  }

  /**
   * 
   * @param {Event} event 
   */
  function onSave(event) {
    event.preventDefault();

    const binCode = $('.yst-add-item-bin-code').text();
    const barcode = barcodeInput.val();
    const quantity = $('#yst-add-item-qty').val();
    const userId = ArecaCookies.read(LOGIN_USER_ID);

    Loader.show();

    const errorMessage = getErrorMessage(barcode, quantity);

    if (errorMessage) {
      showToast('Error', errorMessage);
      focusAndScrollToInput();
      clearItemList();
      populateErrorItem();
      Loader.hide();

      return;
    }

    ArecaAPI.createYstStockTakeLog({
      userId: userId,
      binCode: binCode,
      barcode: barcode,
      quantity: quantity,
      onSuccess: onLogItemSuccess,
      onError: onLogItemError
    });
    
    Loader.hide();
  }

  function attachOnSave() {
    saveButton.on('click', onSave);
  }

  /**
  * @param {KeyboardEvent} event
  */
   function saveKeyboardShortcut(event) {
    if (event.key == 'Enter') { saveButton.click(); }
  }

  function attachOnSaveKeyboardShortcut() {
    barcodeInput.on('keypress', saveKeyboardShortcut);
    $('#yst-add-item-scan-btn').on('keypress', saveKeyboardShortcut);
  }

  function initElements() {
    barcodeInput = $('#yst-add-item-scan-barcode');
    itemList = $('.yst-item-list');
    saveButton = $('#yst-add-qty-save-btn');
  }

  function init() {
    if ($('.page-yst-add-item').length) {
      initElements();
      showBinCode();
      attachOnSave();
      attachOnSaveKeyboardShortcut();
      focusAndScrollToInput();
    }
  }

  return {
    path: path,
    init: init
  }
})();