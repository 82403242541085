/* Fresh Market StockTake Modules */

const FreshMarketSTSessionPage = (function() {
  const path = '/fmst-session.html';

  /**
  * Show toast immediately
  * 
  * @param {String} title 
  * @param {String} message 
  */
   function showToast(title, message) {
    $('#fmst-notification .title').text(title);
    $('#fmst-notification .content').text(message);
    $('#fmst-notification').toast('show');
  }

  function loadOptions(sessions) {
    const selectBlock = $('#outlet-session-select');

    selectBlock.empty();
    const options = sessions.reduce((res, sess, _) => 
     res += `<option value="${sess.id}" >${sess.outlet_code} - ${sess.start_at}</option>`
    , '');
    const defaultOption = '<option value="" disabled selected>not selected</option>';

    selectBlock.append(defaultOption + options);
  }

  function onSuccess(result) {
    const activeSessions = result.yearly_stock_take_sessions;
    // console.log(result);
    // console.info(result.yearly_stock_take_sessions);
    if (activeSessions.length) {
      loadOptions(activeSessions);
    } else {
      // disable select button
      showToast(`Info`, 'No active session found.');
    }
  }

  function onError(result) {
    if (result.status = 404) {
      showToast(`Error ${result.status}`, 'Api not found.');
    } else {
      showToast(`Error ${result.status}`, `Unexpected Error.`);
    }
  }

  // 1. request available session for select
  // 2. if no session showToast error
  function fetchSession() {
    try {
      ArecaAPI.getActiveFMSTSessions({
        onSuccess: onSuccess,
        onError: onError
      });
    } catch (error) {
      console.log(error);
    }
    Loader.hide();
  }

  // 3. onSelect store session_id and outlet_id in cookie
  // 4. redirect to Add Item
  function onSelect(event) {
    event.preventDefault();
    const selectedSessionId = $('#outlet-session-select').val();
    if (selectedSessionId) {
      ArecaCookies.create(FM_SESSION_ID, selectedSessionId , 1);
      ArecaNavigator.redirectTo(FreshMarketSTScanPage.path);
      
    } else {
      showToast('Error', 'Please select stock take session first.');
    }
  }

  function attachOnSelect() {
    $('#select-session-btn').on('click', onSelect);
  }

  function checkNotification() {
    const title = ArecaCookies.read(TOAST_FM_TITLE);
    const msg = ArecaCookies.read(TOAST_FM_CONTENT);
    if (title && msg) {
      showToast(title, msg);
    }
  }

  function init() {
    Loader.show();
    checkNotification();
    fetchSession();
    attachOnSelect();
  }

  return {
    path: path,
    init: init
  }
})();

const FreshMarketSTScanPage = (function() {
  const path = '/fmst-scan.html';

  let binCodeInput = null;
  let itemBarcodeInput = null;
  let itemQuantityInput = null;
  let scanBtn = null;
  let itemList = null; 

  /**
  * Show toast immediately
  * 
  * @param {String} title 
  * @param {String} message 
  */
   function showToast(title, message) {
    $('#fmst-notification .title').text(title);
    $('#fmst-notification .content').text(message);
    $('#fmst-notification').toast('show');
  }

  function clearInput() {
    itemBarcodeInput.val('');
    itemQuantityInput.val('');
  }

  function clearItemList() {
    itemList.empty();
  }

  function showQuantityModal() {
    $('#confirm-quantity-form').toggleClass('menu-active');
    $('.menu-hider').addClass('menu-active');
    $('body').addClass('modal-open');
  }

  function closeModal() {
    $('#confirm-quantity-form').removeClass('menu-active');
    $('.menu-hider').removeClass('menu-active');
    $('body').removeClass('modal-open');
    $('.menu').removeClass('menu-active');
  }
  

  function setQuantity(quantity) {
    itemQuantityInput.val(quantity);
  }

  function populateItem(item) {
    const itemBlock = '<a href="#" data-index="1" data-menu="menu-grn-import-item-qty" ' +
                'data-po-item-id="1" data-item-id="2" class="yst-import-item border border-green2-dark rounded-s shadow-s">' +
                    '<i class="fa font-14 fa-angle-double-right color-green2-dark"></i>' +
                    '<div>' +
                    `<span>[${item.scanned_barcode}] - Article: ${item.article_no}</span>` +
                    `<strong class="grn-import-item-name">${item.description.slice(0, 50).trim()}</strong>` +
                    `<u class="" style="width: auto;">QTY: <i class="received-qty" style="padding-left: 0;">${item.recorded_quantity}</i></u>` +
                    `</div>` +
                '</a>'
    itemList.append(itemBlock);
  }

  function onSuccessGetItem(result) {
    if (result) {
      const itemData = result;
      clearItemList();
      // this is just for display
      itemData['recorded_quantity'] = itemData.scanned_quantity;
      populateItem(itemData);
      ArecaCookies.create(FM_ITEM_ID, itemData.id, 1);
      $('#quantity-form-title').text(`Confirm Quantity (${itemData.uom})`);
      showQuantityModal();
      setQuantity(itemData.scanned_quantity);
    } else {
      showToast('Error', 'Unable to process barcode!');
    }

    return false;
  }

  function onErrorGetItem(result) {
    if (result.responseJSON && result.responseJSON.status?.code == 'AUT-10010-E'){
      const msg = result.responseJSON.status.message;
      showToast('Error', msg);
      ArecaCookies.clearAll();
      ArecaNavigator.redirectTo(LoginPage.path);
    } else if (result.status == 404 && result.responseJSON.errors.length) {
      const msg = result.responseJSON.errors.reduce((acc, err) => acc + `${err.message}\n`, '');
      showToast(`Error ${result.status}`, msg);
    } else if (result.status == 404) {
      console.log(result);
      showToast(`Error ${result.status}`, 'Api path cannot be found.');
    } else if (result.status == 500)  {
      showToast(`Error ${result.status}`, 'Internal server error!');
    } else {
      showToast('Error', 'Unexpected error!');
    }

    return false;
  }

  function validateBarcode(barcode) {
    if (barcode.length < 4) {
      return 'Please enter a valid barcode';
    }
  }


  function onScan(event) {
    event.preventDefault();
    // const binCode = binCodeInput.val();
    const scannedBarcode = itemBarcodeInput.val();
    // console.log('weightedCode:', scannedBarcode.length == 18);
    const error = validateBarcode(scannedBarcode);

    if (!error) {
      Loader.show();

      ArecaAPI.getActiveFMSItem({
        barcode: scannedBarcode,
        onSuccess: onSuccessGetItem,
        onError: onErrorGetItem
      });

      Loader.hide();
    } else {
      showToast('error', error);
    }
  }

  function onLogCreated(result) {
    if (result) {
      clearItemList();
      clearInput();
      populateItem(result);
    } else {
      showToast('Error', 'Unable to process barcode!');
    }

    clearInput();

    return false;
  }

  function onLogError(result) {
    if (result.responseJSON && result.responseJSON.status.code == 'AUT-10010-E'){
      const msg = result.responseJSON.status.message;
      showToast('Error', msg);
      ArecaCookies.clearAll();
      ArecaNavigator.redirectTo(LoginPage.path);
    } else if (result.status == 404 && result.responseJSON.errors.length) {
      const msg = result.responseJSON.errors.reduce((acc, err) => acc + `${err.message}\n`, '');
      showToast(`Error ${result.status}`, msg);
    } else if (result.status == 404) {
      showToast(`Error ${result.status}`, 'Api path cannot be found.');
    } else if (result.status == 500)  {
      showToast(`Error ${result.status}`, 'Internal server error!');
    } else {
      showToast('Error', 'Unexpected error!');
    }
    clearInput();

    return false;
  }

   /**
   * 
   * @param {*} barcode 
   * @param {*} quantity 
   * @return {String} errorMessage?
   */
  function validateInput(barcode, quantity) {
    if (barcode.length < 1) {
      return 'Please enter a valid barcode';
    }
    if (quantity == 0) {
      return 'Quantity cannot be Zero.';
    }
    if (quantity <= -10000 || quantity >= 10000) {
      return 'Quantity must between -9999 and 9999';
    }
  }

  function onSave(event) {
    event.preventDefault();
    // console.log();
    const binCode = binCodeInput.val();
    const scannedBarcode = itemBarcodeInput.val();
    const itemId = ArecaCookies.read(FM_ITEM_ID);
    const quantity = itemQuantityInput.val();
    const sessionId = ArecaCookies.read(FM_SESSION_ID);

    const errorMessage = validateInput(scannedBarcode, quantity);

    if (!errorMessage) {
      ArecaAPI.createFMSTLog({
        itemId: itemId,
        barcode: scannedBarcode,
        binCode: binCode,
        quantity: quantity,
        sessionId: sessionId,
        onSuccess: onLogCreated,
        onError: onLogError,
      });

      closeModal();
    } else {
      showToast('Error', errorMessage);
    }

    return false;
  }


  //   return false;
  // }

  // function runYstAddItemEffect(){
  //   $("#yst-add-item-qty").focus();
  //   $("#yst-add-item-qty").select();
  //   $('html, body').animate({ scrollTop: ($("#yst-add-item-qty").offset().top - 80) }, 1);
  // }

  // if exist show item list & assign quantity

  // Confirm quantity

  // create log

  // reset
  function attachOnSave() {
    $('#save-btn').on('click', onSave);
  }

  function attachOnScan() {
    scanBtn.on('click', onScan);
  }

   /**
   * @param {KeyboardEvent} event
   */
    function inputKeyboardEvents(event) {
      if (event.key == 'Enter') { scanBtn.click(); }
    }
  
    function attachKeyboardEvent() {
      itemBarcodeInput.on('keypress', inputKeyboardEvents);
    }


  function initElements() {
    binCodeInput = $('#bin-code-input');
    itemBarcodeInput = $('#item-barcode-input');
    itemQuantityInput = $('#item-quantity-input');
    scanBtn = $('#scan-button');
    itemList = $('#item-list');
  }

  function authenticateSession() {
    const sessionId = ArecaCookies.read(FM_SESSION_ID);
    if (!sessionId){ 
      ArecaCookies.create(TOAST_FM_TITLE, 'Error', 1);
      ArecaCookies.create(TOAST_FM_CONTENT, 'Invalid session. Please select a valid session', 1);
      ArecaNavigator.redirectTo(FreshMarketSTSessionPage.path);
    }
  }

  // TODO: implement this
  function init() {
    initElements();
    authenticateSession();
    attachOnScan();
    attachOnSave();
    attachKeyboardEvent();
  }

  return {
    path: path,
    init: init
  }
})();