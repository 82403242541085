/* GRN modules */

/** GrnImportPage */
const GrnImportPage = (function() {
  const path = '/grn-import.html';

  /**
   * Show toast immediately
   * 
   * @param {String} title 
   * @param {String} message 
   */
  function showToast(title, message) {
    $('#grn-notification .title').text(title);
    $('#grn-notification .content').text(message);
    $('#grn-notification').toast('show');
  }

  function scrollAndFocusSearchBar() {
    $('#grn-import-search-po-ref-no').focus(function () {
      $('html, body').animate({ scrollTop: ($(this).offset().top - 80) }, 1);
      return false;
    });
    $('#grn-import-search-po-ref-no').focus();
  }

  function showLastDraftBarcode(lastGrnDraft) {
    const draftGrnBarcode = $('.barcode');

    if (draftGrnBarcode.length) {
      ArecaBarcode.show('.barcode', lastGrnDraft)
    }
    $('.grn-last-draft').show();
  }

  function onSearch(_) {
    const value = $("#grn-import-search-po-ref-no").val();
    if (value == '') {
      showToast('Warning', 'PO Reference No Cannot Be Empty.');
    } else {
      ArecaCookies.create('search_po_ref_no', value, 2);
      ArecaNavigator.redirectTo(GrnImportListPage.path);
    }
  }

  function attachOnSearch() {
    $('#grn-import-search').on('click', onSearch);
  }

  /**
   * @param {KeyboardEvent} event
   */
  function keyboardShortcut(event) {
    if (event.key == 'Enter') { $('#grn-import-search').click(); }
  }

  function attachKeyboardShortcut() {
    $('#grn-import-search-po-ref-no').on('keypress', keyboardShortcut);
  }

  function init() {
    const lastGrnDraft = ArecaCookies.read(LAST_GRN_DRAFT);

    if ($('.page-grn-import').length) {
      scrollAndFocusSearchBar();
      if (lastGrnDraft) showLastDraftBarcode(lastGrnDraft);
    }

    attachOnSearch();
    attachKeyboardShortcut();
  }

  return {
    path: path,
    init: init
  }
})();

/** GrnListPage */
const GrnImportListPage = (function() {
  const path = '/grn-import-list.html';

  let grnItems = [];

  /**
  * Show toast immediately
  * 
  * @param {String} title 
  * @param {String} message 
  */
  function showToast(title, message) {
    $('#grn-notification .title').text(title);
    $('#grn-notification .content').text(message);
    $('#grn-notification').toast('show');
  }

  function updateItemList(purchaseOrder) {
    $.each(purchaseOrder.purchase_order_items , function(index, poItem) {
      const itemRow = `<a href="#" data-index="${index}" data-menu="menu-grn-import-item-qty"`  +
      `data-po-item-id="${poItem.id}" data-item-id="${poItem.item_id}"` +
      ' class="grn-import-item border border-red2-dark rounded-s shadow-s">' +
          '<i class="fa font-14 fa-angle-double-right color-red2-dark"></i>' +
          `<span>[${poItem.price_type}] - PO QTY: ${poItem.order_quantity}</span>` +
          `<strong class="grn-import-item-name">${poItem.description.slice(0, 50).trim()}</strong>` +
          '<u class="">QTY<span></span></u>' +
          `<i class="received-qty">${poItem.received_quantity}</i>` +
      '</a>'

      $('.grn-item-list').append(itemRow);
    });
  }

  function showPo(purchaseOrder) {
    $('.grn-import-po-id').text(purchaseOrder.id);
    $('.grn-import-po-ref').text(purchaseOrder.reference_no);
    $('.grn-import-supplier-name').text(purchaseOrder.supplier.name);
    $('.grn-import-outlet-name').text(purchaseOrder.outlet.code);
    $('.grn-import-po-date').text(purchaseOrder.po_date);
    $('.grn-import-expired-date').text(purchaseOrder.expiry_date);

    $('.grn-item-list').empty();
  }

  function setPoRefNo(poRefNo) {
    $('.grn-import-po-ref').text(poRefNo);
  }

  function resetAndfocusBarcodeScan() {
    $('#grn-import-scan-barcode').val('');
    $('#grn-import-scan-barcode').focus();
  }

  function setGrnItems(items) {
    grnItems = items;
  }

  function onSearchSuccess(result) {
    if (result.status.code == 'POR-10002-S') {
      setGrnItems(result.purchase_order.purchase_order_items);
      showPo(result.purchase_order);
      updateItemList(result.purchase_order);
      UI.initMenu();
      resetAndfocusBarcodeScan();
    } else {
      showToast('Error', 'Failed to get PO. Please try again.');
    }
    return false;
  }

  function onSearchError(result) {
    if (result.responseJSON && result.responseJSON.status.code == 'AUT-10010-E'){
      const msg = result.responseJSON.status.message;
      showToast('Error', msg);
      ArecaCookies.clearAll();
      ArecaNavigator.redirectTo(LoginPage.path);
    } else if (result.responseJSON && result.responseJSON.status.code == 'POR-10002-E'){
      const msg = result.responseJSON.status.message;
      showToast('Error', msg)
    } else if (result.status) {
      const msg = result.statusText;
      showToast('Error', msg)
    } else {
      showToast('Error', 'Failed to get PO. Please try again.');
    }
    return false;
  }

  function searchPo(poRefNo) {
    setPoRefNo(poRefNo);
    Loader.show();
    ArecaAPI.searchPo({
      referenceNo: poRefNo,
      onSuccess: onSearchSuccess,
      onError: onSearchError,
    });
    Loader.hide();
  }

  function setReceivedQuantity(index, qty) {
    $(`.grn-item-list [data-index='${index}'] .received-qty`).text(qty);
  }

  function updateItemSuccessHighlight(index) {
    $(`.grn-item-list [data-index='${index}']`).removeClass('border-red2-dark');
    $(`.grn-item-list [data-index='${index}'] i.fa`).removeClass('color-red2-dark');
    $(`.grn-item-list [data-index='${index}']`).addClass('border-green1-dark');
    $(`.grn-item-list [data-index='${index}'] i.fa`).addClass('color-green1-dark');
  }

  /**
   * @param {Event} event 
   */
  function onSearchQuantity(event) {
    event.preventDefault();

    const quantity = $('#grn-import-scan-qty').val();
    const index = $('#menu-grn-import-item-qty').attr('data-index');

    setReceivedQuantity(index, quantity);
    updateItemSuccessHighlight(index);
    resetAndfocusBarcodeScan();
  }

  function attachOnSearchQuantity() {
    $('#grn-import-qty-save-btn').on('click', onSearchQuantity);
  }


  /**
   * @param {KeyboardEvent} event
   */
  function scanQtyKeyboardShortcut(event) {
    if (event.key == 'Enter') {  $('#grn-import-qty-save-btn').click(); }
  }

  function attachScanQtyKeyboardShortcut() {
    $('#grn-import-search-po-ref-no').on('keypress', scanQtyKeyboardShortcut);
  }

  /**
   * @param {KeyboardEvent} event
   */
   function scanBarcodeKeyboardShortcut(event) {
    if (event.key == 'Enter') {
      const scanValue = $(this).val();
      if (scanValue.length) {
        const item = grnItems.find(item => item.barcodes == scanValue);
        $(`.grn-item-list a[data-item-id="${item.item_id}"]`).trigger('click');
      }
    }
  }

  function attachItemScanBarcodeKeyboardShortcut() {
    $('#grn-import-scan-barcode').on('keypress', scanBarcodeKeyboardShortcut);
  }

  function attachKeyboardShortcuts() {
    attachScanQtyKeyboardShortcut();
    attachItemScanBarcodeKeyboardShortcut();
  }

  function getGrnImportData() {
    return $('.grn-item-list a').map(() => {
      const poItemId = $(this).attr('data-po-item-id');
      const grnReceivedQty = $(this).find('.received-qty').text();

      return {
        'poItemId': poItemId,
        'grnReceivedQty': grnReceivedQty,
      }
    }).get();
  }

  function createSuccessImportCookies(refNo) {
    ArecaCookies.create(TOAST_GRN_TITLE, 'Success', 1);
    ArecaCookies.create(TOAST_GRN_CONTENT, 'GRN Draft Submitted.', 1);
    ArecaCookies.create(LAST_GRN_DRAFT, refNo, 1);
    ArecaCookies.clear(SEARCH_PO_REF_NO);
  }

  function clearPoSearchRefNo() {
    ArecaCookies.clear(SEARCH_PO_REF_NO);
  }

  function onImportSucces(result) {
    if (result.status.code == 'GRN-10001-S') {
      createSuccessImportCookies(result.good_received_note.reference_no);
      clearPoSearchRefNo();
      ArecaNavigator.redirectTo(GrnImportPage.path);
    } else {
      showToast('Error', result.status.message);
    }

    Loader.hide();
    return false;
  }

  function onImportError(result) {
    if (result.responseJSON && result.responseJSON.status.code == 'AUT-10010-E') {
      showToast('Error', result.responseJSON.status.message);
      ArecaCookies.clearAll();
    } else if (result.responseJSON){
      showToast('Error', result.responseJSON.status.message);
    } else if (result.status) {
      showToast('Error', result.statusText);
    } else {
      showToast('Error', 'Failed. Please try again.');
    }

    Loader.hide();
    return false;
  }

  /**
   * @param {Event} event
   */
  function onSubmit(event) {
    event.preventDefault();
    Loader.show();

    const poId = $('.grn-import-po-id').text();
    const importData = getGrnImportData();

    ArecaAPI.createGrnFromImport({
      poId: poId,
      importData: importData,
      onSuccess: onImportSucces,
      onError: onImportError
    });
  }

  function attachOnSubmit() {
    $('#grn-import-submit').on('click', onSubmit);
  }

  function init() {
    const poRefNo = ArecaCookies.read(SEARCH_PO_REF_NO);

    if ($('.page-grn-import-list').length) {
      if (poRefNo != null) {
        searchPo(poRefNo);
      } else {
        ArecaNavigator.redirectTo(GrnImportPage.path);
      }
    }

    attachOnSearchQuantity();
    attachKeyboardShortcuts();
    attachOnSubmit();
  }

  return {
    path: path,
    init: init,
  }
})();

/** GrnAddPage */
const GrnAddPage = (function() {
  const path = '/grn-add.html';
  
  let searchInputBox = $('#grn-add-search-draft-ref-no');
  let searchButton = $('#grn-add-search');

  function showToast(title, message) {
    $('#grn-notification .title').text(title);
    $('#grn-notification .content').text(message)
    $('#grn-notification').toast('show');
  }

  function overrideSearchFocus() {
    searchInputBox.focus(function () {
      $('html, body').animate({ scrollTop: ($(this).offset().top - 80) }, 1);
      return false;
    });
  }

  function selectAndFocusSearch() {
    searchInputBox.select();
    searchInputBox.focus();
  }

  function showLastDraftBarcode(lastGrnDraft) {
    const draftGrnBarcode = $('.barcode');

    if (draftGrnBarcode.length) {
      ArecaBarcode.show('.barcode', lastGrnDraft)
    }
    $('.grn-last-draft').show();
  }

  /**
   * @param {Event} event 
   */
  function onSearch(event) {
    event.preventDefault();

    const value = searchInputBox.val();

    if (value == '') {
      showToast('Warning', 'GRN Draft Ref No Cannot Be Empty.');
    } else {
      ArecaCookies.create('search_grn_draft_ref_no', value, 2);
      ArecaNavigator.redirectTo(GrnAddListPage.path);
    }
  }

  function attachOnSearch() {
    searchButton.on('click', onSearch);
  }

  /**
  * @param {KeyboardEvent} event
  */
  function searchKeyboardShortcut(event) {
    if (event.key == 'Enter') { searchButton.click(); }
  }

  function attachSearchKeyboardShortcut() {
    searchInputBox.on('keypress', searchKeyboardShortcut);
  }

  function initElements() {
    searchInputBox = $('#grn-add-search-draft-ref-no');
    searchButton = $('#grn-add-search');
  }


  function init() {
    const lastGrnDraft = ArecaCookies.read(LAST_GRN_ADD_DRAFT);

    if ($('.page-grn-add').length) {
      initElements();
      overrideSearchFocus();
      selectAndFocusSearch();

      if (lastGrnDraft) showLastDraftBarcode(lastGrnDraft);

      attachOnSearch();
      attachSearchKeyboardShortcut();
    }
  }

  return {
    path: path,
    init: init
  }
})();

/** GrnAddListPage */
const GrnAddListPage = (function() {
  const path = '/grn-add-list.html';

  let barcodeInputBox = null;

  /**
  * Show toast immediately
  * 
  * @param {String} title 
  * @param {String} message 
  */
   function showToast(title, message) {
    $('#grn-notification .title').text(title);
    $('#grn-notification .content').text(message);
    $('#grn-notification').toast('show');
  }

  function showDraftNo(draftNo) {
    $('.grn-add-draft-ref').text(draftNo);
  }

  function selectScanBarcode() {
    barcodeInputBox.select();
  }

  function focusScanBarcode() {
    barcodeInputBox.focus();
  }

  function showGrn(grn) {
    $('.grn-add-grn-id').text(grn.id);
    $('.grn-add-supplier-id').text(grn.supplier.id);
    $('.grn-add-outlet-id').text(grn.outlet.id);
    $('.grn-add-draft-ref').text(grn.reference_no);
    $('.grn-add-supplier-name').text(grn.supplier.name);
    $('.grn-add-outlet-name').text(grn.outlet.code);
    $('.grn-add-gr-date').text(grn.gr_date);
  }

  function onDraftSearchSuccess(result) {
    if (result.status.code == 'GRN-10002-S') {
      showGrn(result.good_received_note);

      focusScanBarcode();
    } else {
      showToast('Error', 'Failed to get Draft. Please try again.');
    }
    return false;
  }

  function onDraftSearchError(result) {
    if (result.responseJSON && result.responseJSON.status.code == 'AUT-10010-E'){
      const msg = result.responseJSON.status.message;
      showToast('Error', msg);
      ArecaCookies.clearAll();
      ArecaNavigator.redirectTo(LoginPage.path);
    } else if (result.responseJSON && result.responseJSON.status.code == 'GRN-10003-E'){
      const msg = result.responseJSON.status.message;
      showToast('Error', msg)
    } else if (result.status) {
      const msg = result.statusText;
      showToast('Error', msg)
    } else {
      showToast('Error', 'Failed to get Draft. Please try again.');
    }
    return false;
  }

  function searchDraft(draftNo) {
    Loader.show();
    ArecaAPI.searchGrnDraft({
      refNo: draftNo,
      onSuccess: onDraftSearchSuccess,
      onError: onDraftSearchError,
    })

    Loader.hide();
  }

  function onItemSearchSuccess(result, barcode) {
    if (result.status.code == 'GRN-10003-S') {
      const items = result.items;
      let singleItem = null;
      let singleItemList =  null;

      if (items.length == 1) {
        singleItem = items[0];
        singleItemList = $(`.grn-item-list a[data-item-id="${items[0].id}"]`);
      } 

      if (singleItem && singleItemList.length) {
        singleItemList.trigger('click');
      } else {
        const itemList = items?.reduce((acc, item, index) => {
          if (!$(`.grn-item-list a[data-item-id="${item.id}"][data-item-price-type="${item.price_type}"]`).length) {
            acc += `<a href="#" data-index="${index}'" data-menu="menu-grn-add-item-qty" ` +
            `data-grn-item-id="0" data-item-id="${item.id}" data-item-price-type="${item.price_type}" ` +
            `class="grn-import-item border border-red2-dark rounded-s shadow-s">` +
            `<i class="fa font-14 fa-angle-double-right color-red2-dark"></i>` +
            `<span class="grn-add-scan-value">Scan: ${barcode}</span>` +
            `<strong class="grn-add-item-name">${item.description.slice(0, 20).trim()} ` +
            `[${item.price_type}]</strong><u class="">QTY<span></span></u>` +
            `<i class="received-qty">' + '0' + '</i>` +
            `</a>`
          }

          return acc;
        }, '');
  
        $('.grn-item-list').append(itemList);
        UI.initMenu();
        // if (singleItem) $(`.grn-item-list a[data-index="${items.length}"]`).trigger('click');
      }

      Loader.hide();
      return false;
    }
  }

  function onItemSearchError(result) {
    if (result.responseJSON && result.responseJSON.status.code == 'AUT-10010-E'){
      const msg = result.responseJSON.status.message;
      showToast('Error', msg);
      ArecaCookies.clearAll();
      ArecaNavigator.redirectTo(LoginPage.path);
    } else if (result.responseJSON && result.responseJSON.status.code.length){
      const msg = result.responseJSON.status.message;
      showToast('Error', msg)
    } else if (result.status) {
      const msg = result.statusText;
      showToast('Error', msg)
    } else {
      showToast('Error', 'Failed to get Draft. Please try again');
    }

    selectScanBarcode();
    focusScanBarcode();
    Loader.hide();
    return false;
  }

  function searchItem(barcode) {
    const supplierId = $('.grn-add-supplier-id').text();
    const outletId = $('.grn-add-outlet-id').text();
    const referenceNo = $('.grn-add-draft-ref').text();

    if (barcode?.length && supplierId?.length && outletId?.length) {
      Loader.show();

      ArecaAPI.searchGrnItems({
        searchTerm: barcode,
        outletId: outletId,
        supplierId: supplierId,
        referenceNo: referenceNo,
        onSuccess: (result) => onItemSearchSuccess(result, barcode),
        onError: onItemSearchError,
      })
    }
  }

  /**
   * 
   * @param {KeyboardEvent} event 
   */
  function onScanBarcodeShortcut(event) {
    if (event.key == 'Enter') { 
      const scannedBarcode = $(this).val();
      searchItem(scannedBarcode);
    }

  }

  function attachScanBarcodeKeyboardShortcut() {
    barcodeInputBox.on('keypress', onScanBarcodeShortcut);
    
  }

  /**
   * @param {String} selector 
   */
  function changeBorderSuccess(selector) {
    $(selector).removeClass('border-red2-dark');
    $(`${selector} i.fa`).removeClass('color-red2-dark');
    $(selector).adClass('border-green1-dark');
    $(`${selector} i.fa`).addClass('color-green1-dark');
  }

   /**
   * @param {Event} event 
   */
  function onSaveQty(event) {
    event.preventDefault();

    const qtyInput = barcodeInputBox.val();
    const index = $('#menu-grn-add-item-qty').attr('data-index');
    const initialSelector = `.grn-item-list [data-index=${index}]`;

    $(`${initialSelector} .received-qty`).text(qtyInput);

    changeBorderSuccess(initialSelector);
    barcodeInputBox.val('');
    focusScanBarcode();
  }

  function attachOnSaveQty() {
    $('#grn-add-qty-save-btn').on('click', onSaveQty);
  }

  /**
  * @param {KeyboardEvent} event 
  */
  function onSaveQtyKeyboardShortcuts(event) {
    if(event.key == 'Enter') {
      barcodeInputBox.click();
    }
  }

  function attachOnSaveQtyKeyboardShortcuts() {
    $('#grn-add-qty-save-btn').on('click', onSaveQtyKeyboardShortcuts);
  }

  function onSubmitSucccess(result) {
    if (result.status.code == 'GRN-10001-S') {
      ArecaCookies.create(TOAST_GRN_TITLE, 'Success', 1);
      ArecaCookies.create(TOAST_INDEX_CONTENT, 'Item Add to GRN Draft.', 1);
      ArecaCookies.create(LAST_GRN_ADD_DRAFT,  result.good_received_note.reference_no, 1);
      ArecaCookies.clear(SEARCH_GRN_ADD_DRAFT)
      
      ArecaNavigator.redirectTo(GrnAddPage.path);
    } else {
      showToast('Error', result.status.message);
    }

    Loader.hide();
    return false;
  }

  function onSubmitError(result) {
    if (result.responseJSON && result.responseJSON.status.code == 'AUT-10010-E'){
      const msg = result.responseJSON.status.message;
      showToast('Error', msg);
      ArecaCookies.clearAll();
      ArecaNavigator.redirectTo(LoginPage.path);
    } else if (result.responseJSON){
      const msg = result.responseJSON.status.message;
      showToast('Error', msg)
    } else if (result.status) {
      const msg = result.statusText;
      showToast('Error', msg)
    } else {
      showToast('Error', 'Failed to submit. Please try again');
    }

    Loader.hide();
    return false;
  }

  function getGrnData() {
    return $('.grn-item-list a').map(() => {
      const itemId = $(this).attr('data-item-id'),
            priceType = $(this).attr('data-item-price-type'),
            scanValue = $(this).find('.grn-add-scan-value').text().split(':')[1].trim(),
            grnReceivedQty = $(this).find('.received-qty').text();

      return {
        'itemId': itemId,
        'priceType': priceType,
        'scanValue': scanValue,
        'grnReceivedQty': grnReceivedQty
      }
    }).get();
  }

  /**
  * @param {Event} event 
  */
  function onSubmit(event) {
    Loader.show();

    const grnId = $('.grn-add-grn-id').text();
    const grnData = getGrnData();

    ArecaAPI.draftGrn({
      id: grnId,
      grnData: grnData,
      onSuccess: onSubmitSucccess,
      onError: onSubmitError
    })
  }

  function attachOnSubmit() {
    $('#grn-add-submit').on('click', onSubmit);
  }


  function initElements() {
    barcodeInputBox = $('#grn-add-scan-barcode');
  }

  function init() {
    const draftNo = ArecaCookies.read(SEARCH_GRN_ADD_DRAFT);

    if ($('.page-grn-add-list').length) {
      initElements();
      if (draftNo != null) {
        showDraftNo(draftNo);
        searchDraft(draftNo);
        attachScanBarcodeKeyboardShortcut();
        attachOnSaveQty();
        attachOnSaveQtyKeyboardShortcuts();
        attachOnSubmit();
      } else {
        ArecaNavigator.redirectTo(GrnAddPage.path);
      }
    }
  }

  return {
    path: path,
    init: init
  }
})();
