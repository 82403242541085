'use strict';

const ENABLE_PWA = true;
const ENABLE_AJAX = true;
const IS_DEV = false;
const BASE_URL = 'https://heromarket.arecasystems.com/api/v1';
// const BASE_URL = 'http://localhost:3000/api/v1';


// NOTE: add new modules here
const modules = [
  IndexPage,
  LoginPage,
  GrnImportPage,
  GrnImportListPage,
  GrnAddPage,
  GrnAddListPage,
  YSTBinPage,
  YSTScanPage,
  FreshMarketSTSessionPage,
  FreshMarketSTScanPage,
];

/**
 * Example module placeholder
 */
 const ExamplePage = (function() {
  const path = '/example.html';

  // TODO: implement this
  function init() {

  }

  return {
    path: path,
    init: init
  }
})();
/* Modules declaration END */




/** 
 *  Page Loader 
 * 
 * NOTE:
 *  - Defined all new areca page here and it will autoload based on the url
 *  - will throw an error if the module is not defined properly
 *
 */
const Pages = (function(modules) {
  const MODULE_PATH = modules.reduce((res, mod) => {
    res[mod.path] = mod;
    return res;
  }, {});

  function getCurrentPath() {
    const currentPath = window.location.pathname;

    if (currentPath.includes('.html')) {
      return currentPath;
    } else {
      return `${currentPath}.html`
    }
  }

  // load modules based on page url
  function loadModule() {
    const currentPath = window.location.pathname == '/' ? IndexPage.path : getCurrentPath();
    const currentModule = MODULE_PATH[currentPath];

    if (currentModule) {
      currentModule.init();
    } else {
      console.error(`Page module not found for ${currentPath}!`);
    }
  }

  function init() {
    loadModule();
  }

  return {
    init: init
  }
})(modules);


/** Main */
const Main = (function() {
  function run() {
    const authed = AuthController.authenticate();

    if (!authed) return;
    UI.onLoad();
    UI.init();
    
    Pages.init();
    if (ENABLE_AJAX) AJAXMode.init();
    if (IS_DEV) DevMode.init();
    if (ENABLE_PWA) PWAMode.init();

    setTimeout(() => {
      UI.initOnLogout();
    }, 1000);
  }

  return {
    run: run
  }
})();

Main.run();
