'use strict';

const ArecaAPI = (function() {
  const BASE_HEADER = { 'Access-Control-Allow-Origin': '*' };

  function authenticatedHeader() {
    const authToken = ArecaCookies.read(LOGIN_TOKEN);

    if (authToken == false) return {}

    return {
      'Authorization': `Bearer ${authToken}`
    }
  }

  /**
   * @param {JSONObject} param
   */
  function post({
    path,
    payload,
    onSuccess,
    onError
  }) {
    $.ajax({
      type: 'POST',
      dataType: 'JSON',
      headers: {
        ...BASE_HEADER,
        ...authenticatedHeader(),
      },
      url: `${BASE_URL}/${path}`,
      data: payload,
      success: onSuccess,
      error: onError
    });
  }

  /**
   * @param {JSONObject} param
   */
  function get({
    path,
    payload,
    onSuccess,
    onError
  }) {
    $.ajax({
      type: 'GET',
      dataType: 'JSON',
      headers: {
        ...BASE_HEADER,
        ...authenticatedHeader(),
      },
      url: `${BASE_URL}/${path}`,
      data: payload,
      success: onSuccess,
      error: onError
    });
  }

  /**
   * @param {JSONObject} param
   */
  function login({
    username,
    password,
    onSuccess,
    onError
  }) {
    const data = {
      session: {
          email: username,
          password: password
      }
    }

    post({path: 'login', payload: data, onSuccess, onError });
  }

  /**
   * @param {JSONObject} param
   */
   function searchPo({
    referenceNo,
    onSuccess,
    onError
  }) {
    const data = {
      purchase_order: {
        reference_no: referenceNo
      }
    }

    post({path: 'purchase_orders/search', payload: data, onSuccess, onError });
  }

  /**
   * @param {JSONObject} param
   */
   function createGrnFromImport({
    poId,
    importData,
    onSuccess,
    onError
  }) {
    const data = {
      good_received_note: {
        purchase_order_id: poId,
        grn_data: importData
      }
    }

    post({path: 'good_received_notes', payload: data, onSuccess, onError });
  }

  /**
   * @param {JSONObject} param
   */
   function searchGrnDraft({
    refNo,
    onSuccess,
    onError
  }) {
    const data = {
      good_received_note: {
        reference_no: refNo
      }
    }

    post({path: 'good_received_notes/draft_search', payload: data, onSuccess, onError });
  }

  /**
   * @param {JSONObject} param
   */
   function searchGrnItems({
    searchTerm,
    outletId,
    supplierId,
    referenceNo,
    onSuccess,
    onError
  }) {
    const data = {
      search_term: searchTerm,
      outlet_id: outletId,
      supplier_id: supplierId,
      reference_no: referenceNo
  }

    post({path: 'good_received_notes/grn_item_search', payload: data, onSuccess, onError });
  }

  /**
   * @param {JSONObject} param
   */
   function draftGrn({
    id,
    grnData,
    onSuccess,
    onError
  }) {
    const data = {
      good_received_note: {
        id: id,
        grn_data: grnData
      }
    }

    post({path: 'good_received_notes/add_to_draft', payload: data, onSuccess, onError });
  }

  /**
  * @param {JSONObject} param
  */
  function getActiveYSTSession({
    onSuccess,
    onError
  }) {
    get({path: 'active_stock_take_session', onSuccess, onError });
  }

  /**
  * @param {JSONObject} param
  */
  function createYstStockTakeLog({
    binCode,
    userId,
    barcode,
    quantity,
    onSuccess,
    onError
  }) {
    const data = {
      yearly_stock_take_logs: {
        bin_code: binCode,
        created_by: userId,
        item_barcode_scan: barcode,
        quantity: quantity
      }
    }

    post({path: 'yearly_stock_take_logs', payload: data, onSuccess, onError });
  }

  /**
  * @param {JSONObject} param
  */
   function getActiveFMSTSessions({
    onSuccess,
    onError
  }) {
    get({path: 'fresh_market_stock_take_sessions', onSuccess, onError });
  }

  /**
  * @param {JSONObject} param
  */
   function getActiveFMSItem({
    barcode,
    onSuccess,
    onError
  }) {
    get({path: `fresh_market_stock_take_items/${barcode}`, onSuccess, onError });
  }

  /**
  * @param {JSONObject} param
  */
   function createFMSTLog({
    itemId,
    binCode,
    userId,
    barcode,
    quantity,
    sessionId,
    onSuccess,
    onError
  }) {
    const data = {
      item_id: itemId,
      bin_code: binCode,
      created_by: userId,
      item_barcode_scan: barcode,
      stock_take_session_id: sessionId,
      quantity: quantity
    }

    post({path: 'fresh_market_stock_take_logs', payload: data, onSuccess, onError });
  }


  return {
    get: get,
    post: post,
    login: login,
    searchPo: searchPo,
    createGrnFromImport: createGrnFromImport,
    searchGrnDraft: searchGrnDraft,
    searchGrnItems: searchGrnItems,
    draftGrn: draftGrn,
    getActiveYSTSession: getActiveYSTSession,
    createYstStockTakeLog: createYstStockTakeLog,
    getActiveFMSTSessions: getActiveFMSTSessions,
    getActiveFMSItem: getActiveFMSItem,
    createFMSTLog: createFMSTLog,
  }
})();

